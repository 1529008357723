import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import styled from "styled-components"
import getSiteColor from "../functions/get-site-color"

const RoundedChip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${({ theme, color }) =>
      color
        ? getSiteColor(color, theme)
        : theme === `light`
        ? `rgba(0, 0, 0, 0.24)`
        : `rgba(255, 255, 255, 0.24)`};
  border-radius: 50px;
  padding: 8px 12px;
  min-width: 45px;
  font-size: 0.85rem;
  font-weight: bold;
  color: ${({ theme, color }) =>
    color
      ? getSiteColor(color, theme)
      : theme === `light`
      ? `rgb(0, 0, 0)`
      : `rgb(255, 255, 255)`};
  transition: border 0.3s, background-color 0.3s, color 0.3s, transform 0.1s;

  svg {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
`

const Chip = props => {
  const { children } = props
  const { theme } = useSelector(({ mainReducer }) => mainReducer)

  return (
    <RoundedChip theme={theme} role="presentation" {...props}>
      {children}
    </RoundedChip>
  )
}

Chip.propTypes = {
  color: PropTypes.string,
}

export default Chip
