import parseYearToBE from "../functions/parse-year-to-BE"

const internships = {
  title: {
    en: `Internships`,
    th: `การฝึกงาน`,
  },
  data: [
    {
      startDate: {
        year: {
          en: `2016`,
          th: `${parseYearToBE(2016)}`,
        },
        month: {
          en: `Sep`,
          th: `ก.ย.`,
        },
      },
      endDate: {
        year: {
          en: `2017`,
          th: `${parseYearToBE(2017)}`,
        },
        month: {
          en: `Apr`,
          th: `เม.ย.`,
        },
      },
      job: {
        en: `Teaching Assistantship`,
        th: `ผู้ช่วยสอน`,
      },
      address: {
        en: `Department of Computer Science, Faculty of Science, Prince of Songkla University, Songkhla`,
        th: `ภาควิชาวิทยาการคอมพิวเตอร์ คณะวิทยาศาสตร์ มหาวิทยาลัยสงขลานครินทร์ วิทยาเขตหาดใหญ่ จังหวัดสงขลา`,
      },
      description: [
        {
          en: `Supported lecturer to explain some information in class`,
          th: `สนับสนุนอาจารย์ผู้สอนในการช่วยอธิบายให้กับผู้เรียนเพิ่มเติม`,
        },
        {
          en: `Checked laboratory result of students in class`,
          th: `ตรวจสอบผลการปฏิบัติของผู้เรียนในชั้นเรียน`,
        },
        {
          en: `Checked practical examination result`,
          th: `ตรวจสอบผลการสอบของผู้เรียน`,
        },
      ],
      acquiredSkills: [
        `Logic`,
        `MS Excel`,
        `MS Powerpoint`,
        `MS Word`,
        `Communication`,
      ],
      image: `internships/profile-2.jpg`,
    },
    {
      startDate: {
        year: {
          en: `2016`,
          th: `${parseYearToBE(2016)}`,
        },
        month: {
          en: `May`,
          th: `พ.ค.`,
        },
      },
      endDate: {
        year: {
          en: `2016`,
          th: `${parseYearToBE(2016)}`,
        },
        month: {
          en: `Jun`,
          th: `มิ.ย.`,
        },
      },
      job: {
        en: `Trainee`,
        th: `นักศึกษาฝึกงาน`,
      },
      address: {
        en: `Central Equipment Division, Faculty of Science, Prince of Songkla University, Songkhla`,
        th: `ศูนย์เครื่องมือกลาง คณะวิทยาศาสตร์ มหาวิทยาลัยสงขลานครินทร์ วิทยาเขตหาดใหญ่ จังหวัดสงขลา`,
      },
      description: [
        {
          en: `Wrote the Ubuntu server manual including how to setup and using for personnel in organization`,
          th: `จัดทำคู่มือการติดตั้งและใช้งาน Ubuntu server ให้กับเจ้าหน้าที่ภายในองค์กร`,
        },
        {
          en: `Developed a website with HTML, PHP, SQL, CSS, JavaScript`,
          th: `พัฒนาเว็บไซต์โดยใช้ HTML PHP SQL CSS และ JavaScript`,
        },
        {
          en: `Developed a website that could be use to exported PDF report including graph`,
          th: `พัฒนาเว็บไซต์ให้สามารถนำออกรายงาน PDF พร้อมกราฟได้`,
        },
      ],
      acquiredSkills: [
        `PHP`,
        `JavaScript`,
        `Jquery`,
        `Ajax`,
        `CSS`,
        `Bootstrap`,
        `HTML5`,
        `HTML`,
        `Xampp`,
        `PDF Generating`,
        `User manual creating`,
      ],
      image: `internships/profile-1.jpg`,
    },
  ],
}

export default internships
