import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import styled from "styled-components"

import hexToRgbaColor from "../functions/hex-to-rgba-color"
import getSiteColor from "../functions/get-site-color"
import Chip from "./chip"

const Container = styled.div`
  border: 1px solid
    ${({ theme, siteColor }) =>
      hexToRgbaColor(getSiteColor(siteColor, theme), 0.25)};
  border-radius: 6px;
  overflow: hidden;
  transition: 0.3s;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme, siteColor }) =>
    hexToRgbaColor(getSiteColor(siteColor, theme), 0.2)};
  transition: background-color 0.3s;

  .left {
    min-width: 160px;
    padding: 1rem;
    transition: min-width 0.3s;

    p {
      font-weight: bold;
      margin-top: 6px;
      margin-bottom: 0;
      color: ${({ theme }) => (theme === `light` ? `#000` : `#fff`)};
      transition: color 0.3s;
    }
  }

  .right {
    width: 100%;
    padding: 1rem;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    background-color: ${({ theme }) =>
      theme === `light` ? `#ffffff` : `#2d2d2d`};
    transition: background-color 0.3s;
  }

  @media (max-width: 599px) {
    .left {
      min-width: 120px;
    }
  }
`

const DetailBox = ({ data }) => {
  const { lang, theme, siteColor } = useSelector(
    ({ mainReducer }) => mainReducer
  )

  return (
    <>
      {data.length > 0 && (
        <Container theme={theme} siteColor={siteColor}>
          {data.map((item, index) => (
            <Row key={`interest_${index}`} theme={theme} siteColor={siteColor}>
              <div className="left">
                <p>{item.title?.[lang]}</p>
              </div>
              <div className="right">
                {item.desc.length > 0 && (
                  <>
                    {item.desc.map((item, index) => {
                      return <Chip key={index}>{item}</Chip>
                    })}
                  </>
                )}
              </div>
            </Row>
          ))}
        </Container>
      )}
    </>
  )
}

DetailBox.propTypes = {
  data: PropTypes.array,
}

DetailBox.defaultProps = {
  data: [],
}

export default DetailBox
