const interests = {
  title: {
    en: `Interests`,
    th: `ความสนใจ`,
  },
  data: [
    {
      title: {
        en: `Workout`,
        th: `Workout`,
      },
      desc: [`Weight training`, `Swimming`, `Cycling`],
    },
    {
      title: {
        en: `Sports`,
        th: `Sports`,
      },
      desc: [`Badminton`, `Tennis`],
    },
    // {
    //   title: {
    //     en: `Music`,
    //     th: `Music`,
    //   },
    //   desc: [`Pop`, `Jazz`, `Instrument`],
    // },
    // {
    //   title: {
    //     en: `Movie`,
    //     th: `Movie`,
    //   },
    //   desc: [`Action`, `Superhero`, `Sci-Fi`],
    // },
    {
      title: {
        en: `Video games`,
        th: `Video games`,
      },
      desc: [
        `Battle Realms`,
        `Final Fantasy`,
        `Fall Guys`,
        `The Sims`,
        `Resident Evil`,
        `AoV (RoV)`,
      ],
    },
    // {
    //   title: {
    //     en: `Traits`,
    //     th: `Traits`,
    //   },
    //   desc: [`Active`, `Dog Lover`, `Cat Lover`, `Geek`, `Foodie`],
    // },
  ],
}

export default interests
