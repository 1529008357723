const skills = {
  programming: {
    title: {
      en: `Skills`,
      th: `ทักษะ`,
    },
    data: [
      `React Hooks`,
      `Micro-Frontend (single-spa-react)`,
      `Responsive Web Design`,
      `Figma`,
      `Redux (@reduxjs/toolkit)`,
      `Node.js`,
      `GRPC`,
      `Kafka`,
      `Event Streaming`,
      `Json Web Token`,
      `CI/CD`,
      `Docker`,
      `Vue.js`,
      `JavaScript`,
      `CSS`,
      `HTML`,
      `MongoDB`,
      `PostgresQL`,
      `MySQL`,
      `GraphQL`,
      `Git`,
      `Linux`,
      `PHP`,
      `Laravel`,
      `SEO`,
      `Web Hosting`,
    ],
  },
  language: {
    title: {
      en: `Languages`,
      th: `ภาษา`,
    },
    data: [
      {
        name: `English (Reading)`,
        percent: 95,
      },
      {
        name: `English (Writing)`,
        percent: 80,
      },
      {
        name: `English (Listening)`,
        percent: 60,
      },
      {
        name: `English (Speaking)`,
        percent: 55,
      },
    ],
  },
}

export default skills
