import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import styled from "styled-components"

const ActivityContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1.25rem;

  div.activity-left {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 250px;
    margin-right: 0.75rem;

    span {
      font-size: 1.5rem;
    }

    .activity-date {
      display: flex;
      flex-direction: column;
    }

    .dash {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    @media (max-width: 767px) {
      max-width: 140px;

      span {
        font-size: 1.25rem;
      }
    }
  }

  div.activity-right {
    width: 100%;

    p {
      margin: 0;

      &.name {
        font-size: 1.5rem;
      }
    }

    @media (max-width: 767px) {
      p {
        &.name {
          font-size: 1.25rem;
        }
      }
    }
  }
`

const Activity = ({ data }) => {
  const activitiesData = data
  const { lang } = useSelector(({ mainReducer }) => mainReducer)

  return (
    <>
      {activitiesData.map((activity, activityIndex) => {
        return (
          <ActivityContainer key={`activity_${activityIndex}`}>
            <div className="activity-left">
              <span className="activity-date">
                <span>{activity.startDate.year[lang]}</span>
                <span>{activity.startDate.month[lang]}</span>
              </span>
              {activity.endDate !== undefined && (
                <>
                  <span className="dash">-</span>
                  <span className="activity-date">
                    <span>{activity.endDate.year[lang]}</span>
                    <span>{activity.endDate.month[lang]}</span>
                  </span>
                </>
              )}
            </div>
            <div className="activity-right">
              <p className="name">{activity.name[lang]}</p>
              <p>{activity.description[lang]}</p>
            </div>
          </ActivityContainer>
        )
      })}
    </>
  )
}

Activity.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.shape({
        year: PropTypes.shape({
          en: PropTypes.string,
          th: PropTypes.string,
        }),
        month: PropTypes.shape({
          en: PropTypes.string,
          th: PropTypes.string,
        }),
      }),
      endDate: PropTypes.shape({
        year: PropTypes.shape({
          en: PropTypes.string,
          th: PropTypes.string,
        }),
        month: PropTypes.shape({
          en: PropTypes.string,
          th: PropTypes.string,
        }),
      }),
      name: PropTypes.shape({
        en: PropTypes.string.isRequired,
        th: PropTypes.string.isRequired,
      }),
      description: PropTypes.shape({
        en: PropTypes.string.isRequired,
        th: PropTypes.string.isRequired,
      }),
    })
  ),
}

export default Activity
