import parseYearToBE from "../functions/parse-year-to-BE"
import eduPic1 from "../files/images/education/edu-1.jpg"
import eduPic2 from "../files/images/education/edu-2.jpg"
import eduPic3 from "../files/images/education/edu-3.jpg"
// import eduPic4 from "../files/images/education/edu-4.jpg"
// import eduPic5 from "../files/images/education/edu-5.jpg"
// import eduPic6 from "../files/images/education/edu-6.jpg"
// import eduPic7 from "../files/images/education/edu-7.jpg"
// import eduPic8 from "../files/images/education/edu-8.jpg"
// import eduPic9 from "../files/images/education/edu-9.jpg"
// import eduPic10 from "../files/images/education/edu-10.jpg"

const education = {
  title: {
    en: `Education`,
    th: `การศึกษา`,
  },
  data: [
    {
      // 1
      startDate: {
        year: {
          en: `2013`,
          th: `${parseYearToBE(2013)}`,
        },
        month: {
          en: `Jun`,
          th: `มิ.ย.`,
        },
      },
      endDate: {
        year: {
          en: `2017`,
          th: `${parseYearToBE(2017)}`,
        },
        month: {
          en: `May`,
          th: `พ.ค.`,
        },
      },
      university: {
        en: `Prince of Songkla University, Hat Yai`,
        th: `มหาวิทยาลัยสงขลานครินทร์ วิทยาเขตหาดใหญ่`,
      },
      degree: {
        en: `Bachelor of Science`,
        th: `วิทยาศาสตรบัณฑิต`,
      },
      major: {
        en: `Computer Science`,
        th: `วิทยาการคอมพิวเตอร์`,
      },
    },
  ],
  activities: [
    {
      startDate: {
        year: {
          en: `2016`,
          th: `${parseYearToBE(2016)}`,
        },
        month: {
          en: ``,
          th: ``,
        },
      },
      name: {
        en: `Cloud Computing Training`,
        th: `โครงการ Cloud Computing Training`,
      },
      description: {
        en: `Participant`,
        th: `ผู้เข้าร่วม`,
      },
    },
    {
      startDate: {
        year: {
          en: `2013`,
          th: `${parseYearToBE(2013)}`,
        },
        month: {
          en: ``,
          th: ``,
        },
      },
      endDate: {
        year: {
          en: `2016`,
          th: `${parseYearToBE(2016)}`,
        },
        month: {
          en: ``,
          th: ``,
        },
      },
      name: {
        en: `Cheer Leader Science Club`,
        th: `ชุมนุมผู้นำเชียร์ คณะวิทยาศาสตร์`,
      },
      description: {
        en: `Member`,
        th: `สมาชิก`,
      },
    },
    {
      startDate: {
        year: {
          en: `2013`,
          th: `${parseYearToBE(2013)}`,
        },
        month: {
          en: ``,
          th: ``,
        },
      },
      endDate: {
        year: {
          en: `2015`,
          th: `${parseYearToBE(2015)}`,
        },
        month: {
          en: ``,
          th: ``,
        },
      },
      name: {
        en: `Computer Science Club`,
        th: `ชุมนุมคอมพิวเตอร์ คณะวิทยาศาสตร์`,
      },
      description: {
        en: `Vice president (2015)`,
        th: `รองประธาน (${parseYearToBE(2015)})`,
      },
    },
  ],
  galleryTitle: {
    en: `Activities`,
    th: `กิจกรรม`,
  },
  galleryPhotos: [
    {
      original: eduPic1,
      alt: `edu-1`,
      fullname: `education/edu-1.jpg`,
    },
    {
      original: eduPic2,
      alt: `edu-2`,
      fullname: `education/edu-2.jpg`,
    },
    {
      original: eduPic3,
      alt: `edu-3`,
      fullname: `education/edu-3.jpg`,
    },
    // {
    //   original: eduPic4,
    //   alt: `edu-4`,
    //   fullname: `education/edu-4.jpg`,
    // },
    // {
    //   original: eduPic5,
    //   alt: `edu-5`,
    //   fullname: `education/edu-5.jpg`,
    // },
    // {
    //   original: eduPic6,
    //   alt: `edu-6`,
    //   fullname: `education/edu-6.jpg`,
    // },
    // {
    //   original: eduPic7,
    //   alt: `edu-7`,
    //   fullname: `education/edu-7.jpg`,
    // },
    // {
    //   original: eduPic8,
    //   alt: `edu-8`,
    //   fullname: `education/edu-8.jpg`,
    // },
    // {
    //   original: eduPic9,
    //   alt: `edu-9`,
    //   fullname: `education/edu-9.jpg`,
    // },
    // {
    //   original: eduPic10,
    //   alt: `edu-10`,
    //   fullname: `education/edu-10.jpg`,
    // },
  ],
}

export default education
