import React, { useEffect, useRef } from "react"
// import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import {
  red,
  indigo,
  blue,
  green,
  amber,
  deepOrange,
} from "@mui/material/colors"
import {
  // FaUserCircle,
  FaUserTie,
  FaBriefcase,
  FaSignature,
  FaBolt,
  // FaLanguage,
  FaStar,
  FaUserGraduate,
  FaMedal,
  FaTools,
} from "react-icons/fa"
import {
  MdLocationPin,
  // MdSmartphone,
  MdEmail,
  MdFavorite,
} from "react-icons/md"
import { IoAppsSharp } from "react-icons/io5"
// import { GiFullFolder } from "react-icons/gi"
// import { HiOutlineArrowNarrowRight } from "react-icons/hi"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section"
import Social from "../components/social"
// import Instagram from "../components/instagram"
import WorkExperience from "../components/work-experience"
import MainTitle from "../components/main-title"
import Chip from "../components/chip"
import Education from "../components/education"
import Activity from "../components/activity"
import ExamPrograms from "../components/exam-programs"
import Gallery from "../components/gallery"
// import ApirakButton from "../components/apirak-button"
import ShiningEffect from "../components/shining-effect"
import { StyledLink } from "../components/styles"
import {
  LetterA,
  LetterB,
  LetterE,
  LetterK,
  LetterS,
  LetterU,
} from "../components/icons/letters"
import DetailBox from "../components/detail-box"
import index from "../static/index"
import about from "../static/about"
import skills from "../static/skills"
import workExprience from "../static/work-experience"
import internships from "../static/internships"
import education from "../static/education"
import examPrograms from "../static/exam-programs"
import interests from "../static/interests"
import developmentTools from "../static/development-tools"
// import portfolio from "../static/portfolio"
import { setCurrentPage, setCurrentTopic } from "../states/slices/main-slice"
import { playSound } from "../states/slices/sound-slice"

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8rem;

  .profile-pic {
    width: 100%;
    max-width: 10rem;
    min-width: 200px;
    border-radius: 100%;
    position: absolute;
    top: -90px;
    border: 5px solid
      ${({ theme }) =>
        theme === `light` ? `rgb(255, 255, 255)` : `rgb(45, 45, 45)`};
    background-color: ${({ theme }) =>
      theme === `light` ? `rgb(255, 255, 255)` : `rgb(45, 45, 45)`};
    transition: 0.3s;

    .gatsby-image-wrapper {
      img {
        border-radius: 100%;
      }
    }
  }

  @media (max-width: 595px) {
    flex-direction: column;
    align-items: center;
    margin-top: 6rem;

    .profile-pic {
      min-width: 150px;
    }
  }
`

const InfoText = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0;
  margin-bottom: 1rem;

  svg {
    font-size: 1.25rem;
    margin-right: 8px;
    opacity: 0.8;
  }
`

const NickName = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-right: 0;

    path {
      transition: 0.3s;
    }
  }
`

const sectionStyles = {
  marginBottom: `1.5rem`,
}

const HomePage = ({ location }) => {
  const { lang, theme, currentTopic, pageGroup, pageCount } = useSelector(
    ({ mainReducer }) => mainReducer
  )
  const dispatch = useDispatch()

  const skillsRef = useRef(null)
  const examProgramsRef = useRef(null)
  const portfolioRef = useRef(null)

  useEffect(() => {
    dispatch(setCurrentPage({ page: `index` }))
  }, [dispatch])

  useEffect(() => {
    const padding = 75

    setTimeout(() => {
      switch (currentTopic) {
        case `skills`:
          window.scrollTo({
            top: skillsRef.current.offsetTop - padding,
            behavior: "smooth",
          })
          break

        case `react-apps`:
          window.scrollTo({
            top: examProgramsRef.current.offsetTop - padding,
            behavior: "smooth",
          })
          break

        case `portfolio`:
          window.scrollTo({
            top: portfolioRef.current.offsetTop - padding,
            behavior: "smooth",
          })
          break

        default:
          break
      }
    }, 100)
  }, [currentTopic])

  useEffect(() => {
    switch (pageGroup) {
      case `app`:
        dispatch(setCurrentTopic(`react-apps`))
        break

      case `portfolio`:
        dispatch(setCurrentTopic(`portfolio`))
        break

      default:
        break
    }
  }, [pageGroup, dispatch])

  useEffect(() => {
    if (pageCount === 0) {
      const getLocationSearch = location.search.replace(`?`, ``)
      const params = getLocationSearch.split(`&`)

      let newParams = []
      for (let param of params) {
        newParams = [
          ...newParams,
          {
            name: param.split(`=`)[0],
            value: param.split(`=`)[1],
          },
        ]
      }

      if (newParams.find(elem => elem.name === `section`) !== undefined) {
        dispatch(
          setCurrentTopic(newParams.find(elem => elem.name === `section`).value)
        )
      }
    }
  }, [pageCount, location, dispatch])

  return (
    <Layout>
      <Seo title={index.pageName[lang]} />

      <div style={{ marginTop: `7.5rem` }}>
        <div style={{ position: `relative` }}>
          <Section style={sectionStyles}>
            <InfoBlock theme={theme}>
              <div className="profile-pic">
                <ShiningEffect
                  style={{
                    borderRadius: `100%`,
                  }}
                  width={35}
                  animationDuration="5s"
                  animationDelay="1s"
                  forceBgColor="light"
                />
                <StaticImage
                  src="../files/images/about/basuke2023-2.jpg"
                  alt="Basuke Profile Image"
                  style={{
                    width: `100%`,
                    height: `100%`,
                  }}
                  imgStyle={{
                    objectFit: `cover`,
                    objectPosition: `center top`,
                  }}
                  placeholder="blurred"
                  formats={[`auto`, `webp`, `avif`]}
                  draggable={false}
                />
              </div>
              <div>
                <InfoText>
                  <FaUserTie />
                  {about.profile.fullname[lang]}
                </InfoText>
                <InfoText>
                  <FaBriefcase />
                  {about.profile.job[lang]}
                </InfoText>
                <InfoText>
                  <MdLocationPin />
                  {about.profile.address[lang]}
                </InfoText>
                {/* <InfoText>
                  <FaUserGraduate />
                  {about.profile.graduation[lang]}
                </InfoText> */}
                <InfoText>
                  <MdEmail />
                  <StyledLink
                    href={`mailto:${about.profile.email}`}
                    color="primary"
                    onClick={() => dispatch(playSound(`confirm`))}
                  >
                    {about.profile.email}
                  </StyledLink>
                </InfoText>
                <InfoText>
                  <FaSignature />
                  <NickName>
                    <LetterB
                      size="2rem"
                      bgColor={red[theme === `light` ? 700 : 200]}
                      color={theme === `light` ? `#fff` : `#191919`}
                    />
                    <LetterA
                      size="2rem"
                      bgColor={deepOrange[theme === `light` ? 700 : 200]}
                      color={theme === `light` ? `#fff` : `#191919`}
                    />
                    <LetterS
                      size="2rem"
                      bgColor={amber[theme === `light` ? 700 : 200]}
                      color={theme === `light` ? `#fff` : `#191919`}
                    />
                    <LetterU
                      size="2rem"
                      bgColor={green[theme === `light` ? 700 : 200]}
                      color={theme === `light` ? `#fff` : `#191919`}
                    />
                    <LetterK
                      size="2rem"
                      bgColor={blue[theme === `light` ? 700 : 200]}
                      color={theme === `light` ? `#fff` : `#191919`}
                    />
                    <LetterE
                      size="2rem"
                      bgColor={indigo[theme === `light` ? 700 : 200]}
                      color={theme === `light` ? `#fff` : `#191919`}
                    />
                  </NickName>
                </InfoText>
              </div>
            </InfoBlock>

            <Social />
          </Section>
        </div>

        <Section style={sectionStyles} title={about.title[lang]}>
          <p style={{ textAlign: `justify` }}>"{about.quote[lang]}"</p>
        </Section>

        <Section
          style={sectionStyles}
          title={workExprience.title[lang]}
          icon={<FaMedal />}
        >
          <WorkExperience data={workExprience.data} />
        </Section>

        <Section
          style={sectionStyles}
          title={internships.title[lang]}
          icon={<FaStar />}
        >
          <WorkExperience data={internships.data} />
        </Section>

        <Section
          style={sectionStyles}
          title={education.title[lang]}
          icon={<FaUserGraduate />}
        >
          <Education data={education.data} />

          <div style={{ marginBottom: `3rem` }} />
          <MainTitle align="left" title={education.galleryTitle[lang]} />
          <Activity data={education.activities} />

          <div style={{ marginBottom: `3rem` }} />
          <Gallery
            photos={education.galleryPhotos}
            grid={{ column: 3, row: 2 }}
          />

          <div style={{ marginBottom: `1rem` }} />
        </Section>

        <div ref={examProgramsRef}>
          <Section
            style={sectionStyles}
            title={examPrograms.title[lang]}
            icon={<IoAppsSharp />}
            blink={currentTopic === `react-apps`}
          >
            <ExamPrograms />
          </Section>
        </div>

        <div ref={skillsRef}>
          <Section
            style={sectionStyles}
            title={skills.programming.title[lang]}
            icon={<FaBolt />}
            blink={currentTopic === `skills`}
          >
            <div
              style={{
                marginBottom: `1rem`,
                width: `100%`,
                display: `flex`,
                flexDirection: `column`,
              }}
            >
              <div
                style={{
                  display: `flex`,
                  width: `100%`,
                  flexWrap: `wrap`,
                  gap: `8px`,
                }}
              >
                {skills.programming.data.map((skill, skillIndex) => {
                  return <Chip key={`skill_${skillIndex}`}>{skill}</Chip>
                })}
              </div>
            </div>
          </Section>
        </div>

        {/* <Section style={sectionStyles} title={skills.language.title[lang]} icon={<FaLanguage />}>
          {skills.language.data.map((skill, skillIndex) => {
            return (
              <div key={`skill_${skillIndex}`} className="description">
                <div className="description-left">
                  <p>{skill.name}</p>
                </div>
                <div className="description-right">
                  <ProgressBar percent={skill.percent} />
                </div>
              </div>
            )
          })}
        </Section> */}

        <Section
          style={sectionStyles}
          title={developmentTools.title[lang]}
          icon={<FaTools />}
        >
          <div style={{ marginBottom: `1rem` }}>
            <DetailBox data={developmentTools.data} />
          </div>
        </Section>

        {/* <div ref={portfolioRef}>
          <Section
            style={sectionStyles}
            title={portfolio.title[lang]}
            icon={<GiFullFolder />}
            blink={currentTopic === `portfolio`}
          >
            <div style={{ marginBottom: `1rem` }}>
              <p>{portfolio.data[0].name[lang]}</p>
              <p
                style={{
                  fontSize: `0.75rem`,
                  color: `rgba(${
                    theme === `light` ? `0, 0, 0` : `255, 255, 255`
                  }, 0.75)`,
                  transition: `color 0.3s`,
                }}
              >
                {portfolio.data[0].techUses}
              </p>
              <Gallery photos={portfolio.data[0].galleryPhotos} />
              <div
                style={{
                  display: `flex`,
                  justifyContent: `center`,
                  marginTop: `2rem`,
                }}
              >
                <ApirakButton
                  onClick={() => {
                    navigate(`/portfolio/`)
                    dispatch(playSound(`confirm`))
                  }}
                >
                  <span>{portfolio.seeMoreButton[lang]}</span>
                  <HiOutlineArrowNarrowRight style={{ marginLeft: 8 }} />
                </ApirakButton>
              </div>
            </div>
          </Section>
        </div> */}

        <Section
          style={sectionStyles}
          title={interests.title[lang]}
          icon={<MdFavorite />}
        >
          <div style={{ marginBottom: `1rem` }}>
            <DetailBox data={interests.data} />
          </div>
        </Section>
      </div>
    </Layout>
  )
}

export default HomePage
