import React from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { FaLinkedin, FaGithub } from "react-icons/fa"

import ProgramButton from "./program-button"
import about from "../static/about"
import { playSound } from "../states/slices/sound-slice"

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  gap: 1rem;

  a {
    text-decoration: none;
    border-radius: 100%;
  }
`

const Social = () => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(playSound(`confirm`))
  }

  return (
    <>
      <Flex>
        <a
          aria-label={`${about.social.linkedIn.name}-icon`}
          href={about.social.linkedIn.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
        >
          <ProgramButton style={{ margin: 0 }}>
            <FaLinkedin />
            <span>{about.social.linkedIn.name}</span>
          </ProgramButton>
        </a>
        <a
          aria-label={`${about.social.github.name}-icon`}
          href={about.social.github.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
        >
          <ProgramButton style={{ margin: 0 }}>
            <FaGithub />
            <span>{about.social.github.name}</span>
          </ProgramButton>
        </a>
        {/* <a
          aria-label={`${about.social.instagram.name}-icon`}
          href={about.social.instagram.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
        >
          <ProgramButton style={{ margin: 0 }}>
            <FaInstagram />
            <span>{about.social.instagram.name}</span>
          </ProgramButton>
        </a> */}
        {/* <a
          aria-label="fb-icon"
          href={about.social.fb.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
        >
          <ProgramButton style={{ margin: 0 }}>
            <FaFacebook />
            <span>{about.social.fb.name}</span>
          </ProgramButton>
        </a> */}
        {/* <a
          aria-label={`${about.social.line.name}-icon`}
          href={about.social.line.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
        >
          <ProgramButton style={{ margin: 0 }}>
            <FaLine />
            <span>{about.social.line.name}</span>
          </ProgramButton>
        </a> */}
      </Flex>
    </>
  )
}

export default Social
