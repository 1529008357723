import myPic1 from "../files/images/personal-info/basuke-apirak-1.jpg"
import myPic2 from "../files/images/personal-info/basuke-apirak-2.jpg"
import myPic3 from "../files/images/personal-info/basuke-apirak-3.jpg"
import myPic4 from "../files/images/personal-info/basuke-apirak-4.jpg"
import myPic5 from "../files/images/personal-info/basuke-apirak-5.jpg"
import myPic6 from "../files/images/personal-info/basuke-apirak-6.jpg"

const about = {
  quote: {
    en: `I work as a software developer. I like exploring ideas and trying out experiences. I prioritize self care and love going to the gym. I am also like playing badminton and hitting the tennis court.`,
    th: `xxx`,
  },
  title: {
    en: `About`,
    th: `เกี่ยวกับ`,
  },
  profile: {
    fullname: {
      en: `Apirak Suwanyotee (Basuke 🏀)`,
      th: `อภิรักษ์ สุวรรณโยธี (บาส 🏀)`,
    },
    job: {
      en: `Software Engineer`,
      th: `Software Engineer`,
    },
    graduation: {
      en: `Computer Science #23 | PSU`,
      th: `Computer Science #23 | PSU`,
    },
    address: {
      en: `Bangkok City, Thailand`,
      th: `กรุงเทพมหานคร ประเทศไทย`,
    },
    email: `Lt.ApirakSuwanyotee@gmail.com`,
  },
  social: {
    title: {
      en: `Social Media`,
      th: `สื่อสังคม`,
    },
    facebook: {
      name: `Facebook`,
      link: `https://www.facebook.com/apirak.basuke/`,
    },
    instagram: {
      name: `Instagram`,
      id: `basuke_apirak`,
      link: `https://www.instagram.com/basuke_apirak/`,
    },
    linkedIn: {
      name: `LinkedIn`,
      link: `https://www.linkedin.com/in/apirak-suwanyotee-391b9725a/`,
    },
    line: {
      name: `Line`,
      id: `basdarkholy`,
      link: `https://line.me/ti/p/d30RvzsOGD`,
    },
    github: {
      name: `Github`,
      link: `https://github.com/darkholy40`,
    },
  },
  galleryPhotos: [
    {
      original: myPic1,
      alt: `basuke-apirak-1`,
      fullname: `personal-info/basuke-apirak-1.jpg`,
      url: `https://www.instagram.com/p/Cb-OPkVpAf3/`,
    },
    {
      original: myPic2,
      alt: `basuke-apirak-2`,
      fullname: `personal-info/basuke-apirak-2.jpg`,
      url: `https://www.instagram.com/p/CbHREcopCu1/`,
    },
    {
      original: myPic3,
      alt: `basuke-apirak-3`,
      fullname: `personal-info/basuke-apirak-3.jpg`,
      url: `https://www.instagram.com/p/CapAnm8Jr9X/`,
    },
    {
      original: myPic4,
      alt: `basuke-apirak-4`,
      fullname: `personal-info/basuke-apirak-4.jpg`,
      url: `https://www.instagram.com/p/CaV-FMspzM6/`,
    },
    {
      original: myPic5,
      alt: `basuke-apirak-5`,
      fullname: `personal-info/basuke-apirak-5.jpg`,
      url: `https://www.instagram.com/p/CW5P4S5p63E/`,
    },
    {
      original: myPic6,
      alt: `basuke-apirak-6`,
      fullname: `personal-info/basuke-apirak-6.jpg`,
      url: `https://www.instagram.com/p/CHxpqyvJ3HN/`,
    },
  ],
}

export default about
