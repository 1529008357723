import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { useResizeDetector } from "react-resize-detector"
import styled from "styled-components"
import { MdFiberManualRecord } from "react-icons/md"

import Image from "./image"
import Gallery from "./gallery"
import Chip from "./chip"

const WorkExpContainer = styled.div`
  margin-bottom: 2.25rem;
`

const UpperRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

const LowerRow = styled(UpperRow)`
  flex-direction: column;
`

const LeftColumn = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 250px;
  margin-right: 0.75rem;

  .date-block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    span {
      font-size: 1.5rem;
    }

    .work-date {
      display: flex;
      flex-direction: column;
    }

    .dash {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  .profile-pic {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;

    .img {
      width: 100%;
      height: 100%;
      max-width: 100px;
      max-height: 100px;
      border-radius: 100%;
      overflow: hidden;

      img {
        border-radius: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    max-width: 140px;

    .date-block {
      span {
        font-size: 1.25rem;
      }
    }
  }

  @media (max-width: 599px) {
    .profile-pic {
      margin-bottom: 1rem;
    }
  }
`

const RightColumn = styled.div`
  width: 100%;

  p {
    margin: 0;

    &.job {
      font-size: 1.5rem;
    }

    &.address {
      font-size: 0.75rem;
      margin-bottom: 0.75rem;
    }
  }

  div.job-description {
    display: flex;

    .left {
      margin-right: 0.5rem;
    }
  }

  @media (max-width: 767px) {
    p {
      &.job {
        font-size: 1.25rem;
      }
    }
  }
`

const ProjectShowcaseSection = styled.div`
  margin-top: 1.25rem;

  .title {
    font-weight: bold;
    margin-bottom: 0.75rem;
  }
`

const SkillsSection = styled.div`
  margin-top: 1.25rem;
  display: flex;
  gap: 0.75rem;

  .title {
    margin-top: 6px;
    font-weight: bold;
  }
`

const AcquiredSkills = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const WorkExperience = ({ data }) => {
  const wonkExpData = data
  const { lang, siteColor } = useSelector(({ mainReducer }) => mainReducer)
  const { width, ref } = useResizeDetector()
  const [displayMobileMode, setDisplayMobileMode] = React.useState(false)

  React.useEffect(() => {
    // on resize logic
    if (width < 520) {
      setDisplayMobileMode(true)
    } else {
      setDisplayMobileMode(false)
    }
  }, [width])

  const renderWorkExpDescription = descriptions =>
    descriptions?.length > 0 &&
    descriptions.map((workExperience, workExperienceIndex) => {
      return (
        <div
          key={`work_desc_${workExperienceIndex}`}
          className="job-description"
        >
          <span className="left">
            <MdFiberManualRecord style={{ fontSize: `0.6rem` }} />
          </span>
          <span className="right">{workExperience[lang]}</span>
        </div>
      )
    })

  return (
    <>
      {wonkExpData.map((work, index) => {
        return (
          <div key={`work_exp_${index}`} ref={ref}>
            <WorkExpContainer>
              <UpperRow className="top">
                <LeftColumn>
                  <div className="date-block">
                    <span className="work-date">
                      <span>{work.startDate.year[lang]}</span>
                      <span>{work.startDate.month[lang]}</span>
                    </span>
                    <span className="dash">-</span>
                    <span className="work-date">
                      <span>{work.endDate.year[lang]}</span>
                      <span>{work.endDate.month[lang]}</span>
                    </span>
                  </div>
                  {work.image && (
                    <div className="profile-pic">
                      <div className="img">
                        <Image
                          src={work.image}
                          imgStyle={{ objectFit: `cover` }}
                        />
                      </div>
                    </div>
                  )}
                </LeftColumn>
                <RightColumn>
                  <p className="job">{work.job[lang]}</p>
                  <p className="address">{work.address[lang]}</p>
                  {!displayMobileMode && (
                    <>
                      <div>{renderWorkExpDescription(work.description)}</div>

                      {work.projects?.length > 0 && (
                        <ProjectShowcaseSection color={siteColor}>
                          <p className="title">Projects:</p>
                          <div>{renderWorkExpDescription(work.projects)}</div>
                        </ProjectShowcaseSection>
                      )}

                      {work.acquiredSkills?.length > 0 && (
                        <SkillsSection>
                          <p className="title">Skills:</p>
                          <AcquiredSkills>
                            {work.acquiredSkills.map((skill, skillIndex) => (
                              <Chip
                                key={`acquired-skill-${skillIndex}`}
                                color={siteColor}
                              >
                                {skill}
                              </Chip>
                            ))}
                          </AcquiredSkills>
                        </SkillsSection>
                      )}
                    </>
                  )}
                </RightColumn>
              </UpperRow>
              {displayMobileMode && (
                <LowerRow>
                  <div />
                  <RightColumn>
                    <div>{renderWorkExpDescription(work.description)}</div>

                    {work.projects?.length > 0 && (
                      <ProjectShowcaseSection color={siteColor}>
                        <p className="title">Projects:</p>
                        <div>{renderWorkExpDescription(work.projects)}</div>
                      </ProjectShowcaseSection>
                    )}

                    {work.acquiredSkills?.length > 0 && (
                      <SkillsSection>
                        <p className="title">Skills:</p>
                        <AcquiredSkills>
                          {work.acquiredSkills.map((skill, skillIndex) => (
                            <Chip
                              key={`acquired-skill-${skillIndex}`}
                              color={siteColor}
                            >
                              {skill}
                            </Chip>
                          ))}
                        </AcquiredSkills>
                      </SkillsSection>
                    )}
                  </RightColumn>
                </LowerRow>
              )}
              {work.galleryPhotos?.length > 0 && (
                <>
                  <div style={{ marginBottom: `1rem` }} />
                  <Gallery
                    photos={work.galleryPhotos}
                    grid={{ column: 4, row: 1 }}
                  />
                </>
              )}
            </WorkExpContainer>
          </div>
        )
      })}
    </>
  )
}

WorkExperience.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.shape({
        year: PropTypes.shape({
          en: PropTypes.string,
          th: PropTypes.string,
        }),
        month: PropTypes.shape({
          en: PropTypes.string,
          th: PropTypes.string,
        }),
      }),
      endDate: PropTypes.shape({
        year: PropTypes.shape({
          en: PropTypes.string,
          th: PropTypes.string,
        }),
        month: PropTypes.shape({
          en: PropTypes.string,
          th: PropTypes.string,
        }),
      }),
      job: PropTypes.shape({
        en: PropTypes.string.isRequired,
        th: PropTypes.string.isRequired,
      }),
      address: PropTypes.shape({
        en: PropTypes.string.isRequired,
        th: PropTypes.string.isRequired,
      }),
      description: PropTypes.arrayOf(
        PropTypes.shape({
          en: PropTypes.string.isRequired,
          th: PropTypes.string.isRequired,
        })
      ),
      acquiredSkills: PropTypes.arrayOf(PropTypes.string),
      image: PropTypes.string,
      galleryPhotos: PropTypes.arrayOf(
        PropTypes.shape({
          original: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
          fullname: PropTypes.string.isRequired,
        })
      ),
    })
  ),
}

export default WorkExperience
