import parseYearToBE from "../functions/parse-year-to-BE"
// import rtaPic1 from "../files/images/work-experience/rta/rta-1.jpg"
// import rtaPic2 from "../files/images/work-experience/rta/rta-2.jpg"
// import rtaPic3 from "../files/images/work-experience/rta/rta-3.jpg"
// import rtaPic4 from "../files/images/work-experience/rta/rta-4.jpg"
// import rtaPic5 from "../files/images/work-experience/rta/rta-5.jpg"
// import rtaPic6 from "../files/images/work-experience/rta/rta-6.jpg"

const workExp = {
  title: {
    en: `Work Experience`,
    th: `ประสบการณ์การทำงาน`,
  },
  data: [
    {
      // 4
      startDate: {
        year: {
          en: `2023`,
          th: `${parseYearToBE(2023)}`,
        },
        month: {
          en: `May`,
          th: `พ.ค.`,
        },
      },
      // endDate: {
      //   year: {
      //     en: `Present`,
      //     th: `ปัจจุบัน`,
      //   },
      //   month: {
      //     en: ``,
      //     th: ``,
      //   },
      // },
      endDate: {
        year: {
          en: `2024`,
          th: `${parseYearToBE(2024)}`,
        },
        month: {
          en: `Jul`,
          th: `ก.ค.`,
        },
      },
      job: {
        en: `Software Engineer`,
        th: `Software Engineer`,
      },
      address: {
        en: `SKY ICT Public Company Limited., Bangkok`,
        th: `SKY ICT Public Company Limited., Bangkok`,
      },
      description: [
        {
          en: `Analyzed and designed the repository structure for the Open Platform project`,
          th: `xxx`,
        },
        {
          en: `Developed front-end web applications using React Hooks based on designs created in Figma (web-based graphic design tool)`,
          th: `xxx`,
        },
        {
          en: `Developed front-end web applications using micro-frontend architecture with single-spa and React for the Open Platform and the Visitor Management System`,
          th: `xxx`,
        },
        {
          en: `Developed a BFF (Backend for Frontend) as an API gateway for front-end use, supporting both RESTful and GraphQL APIs for the Open Platform and the Visitor Management System`,
          th: `xxx`,
        },
        {
          en: `Created models according to API specifications for each microservice in the Open Platform`,
          th: `xxx`,
        },
        {
          en: `Developed event streaming functions for synchronize databases across microservices by exchanging messages through Kafka for the Open Platform`,
          th: `xxx`,
        },
        {
          en: `Implemented the integration of devices, such as smart card readers and QR code readers, to seamlessly operate in conjunction with the web application for the Visitor Management System`,
          th: `xxx`,
        },
      ],
      projects: [
        {
          en: `Workspace Management`,
          th: `Workspace Management`,
        },
        {
          en: `User Management`,
          th: `User Management`,
        },
        {
          en: `Visitor Access Management`,
          th: `Visitor Access Management`,
        },
        {
          en: `Smart Card Reader Integration`,
          th: `Smart Card Reader Integration`,
        },
      ],
      acquiredSkills: [
        `React Hooks`,
        `Micro-Frontend (single-spa-react)`,
        `Responsive Web Design`,
        `Figma`,
        `Redux`,
        `Node.js`,
        `GRPC`,
        `Kafka`,
        `Event Streaming`,
        `GraphQL`,
        `Json Web Token`,
        `MongoDB`,
        `PostgreSQL`,
        `CI/CD`,
        `Docker`,
      ],
      image: `work-experience/profile-4.jpg`,
    },
    {
      // 3
      startDate: {
        year: {
          en: `2019`,
          th: `${parseYearToBE(2019)}`,
        },
        month: {
          en: `Jun`,
          th: `มิ.ย.`,
        },
      },
      endDate: {
        year: {
          // en: `Present`,
          // th: `ปัจจุบัน`,
          en: `2022`,
          th: `${parseYearToBE(2022)}`,
        },
        month: {
          en: `Dec`,
          th: `ธ.ค.`,
        },
      },
      job: {
        en: `Commissioned Officer (Military Software Engineer)`,
        th: `นายทหารประจำแผนกวิเคราะห์และพัฒนาระบบ`,
      },
      address: {
        en: `Royal Thai Army Headquarters, Bangkok`,
        th: `กองบัญชาการกองทัพบก ถนนราชดำเนินนอก เขตพระนคร กรุงเทพฯ`,
      },
      description: [
        {
          en: `Analyzed and designed a frontend and backend systems, including relational and non-relational databases, for the Army's internal systems`,
          th: `วิเคราะห์และออกแบบระบบหน้าบ้านและหลังบ้าน รวมถึงฐานข้อมูลแบบ relational หรือ non-relational`,
        },
        {
          en: `Developed a web application and other systems as assigned, using the React.js framework for the Army's internal systems`,
          th: `พัฒนาเว็บแอปพลิเคชันรวมถึงส่วนอื่นๆ ตามที่ได้รับมอบหมายโดยใช้ React.js สำหรับการพัฒนาในส่วนหน้าบ้าน`,
        },
        {
          en: `Developed an API and a web application and to connect the database to Thailand's Department of Provincial Administration for requesting civil registration data, using React.js and Node.js`,
          th: `พัฒนา RESTful API บน node.js หรือ GraphQL API ในการส่งข้อมูลให้กับส่วนหน้าบ้าน`,
        },
        {
          en: `Established an installation manual and user guide for personnel within the Army organization`,
          th: `จัดทำคู่มือการใช้งานสำหรับการติดตั้งและใช้งานโปรแกรมระบบ`,
        },
      ],
      acquiredSkills: [
        `Gatsby`,
        `Next.js`,
        `React Hooks`,
        `React.js`,
        `Redux`,
        `Responsive Web Design`,
        `Json Web Token`,
        `Python`,
        `MongoDB`,
        `MySQL`,
        `Node.js`,
        `Strapi`,
        `Apollo GraphQL`,
        `GraphQL`,
        `Git`,
        `Object-Oriented Programming (OOP)`,
        `CSS`,
        `HTML5`,
        `HTML`,
      ],
      projects: [
        {
          en: `RCOD (Reduction of Copies of Documents)`,
          th: `ระบบทดแทนสำเนาเอกสารราชการ`,
        },
        {
          en: `GE-PDX (Government Employee Personal Data Exchange)`,
          th: `โปรแกรมพนักงานราชการในระบบฐานข้อมูลกำลังพลอิเล็กทรอนิกส์`,
        },
        {
          en: `Financial Status Data Import System (Liabilities)`,
          th: `โปรแกรมระบบข้อมูลกำลังพลกองทัพบกที่ได้รับวัคซีนโควิด 19`,
        },
        {
          en: `Prize Randomizer System for the Directorate of Personnel, Royal Thai Army`,
          th: `ระบบสุ่มจับรางวัลกรมกำลังพลทหารบก`,
        },
        {
          en: `Royal Thai Army Personnel COVID-19 Vaccination Data System`,
          th: `ระบบงานนำเข้าข้อมูลสถานภาพทางการเงิน (หนี้สิน)`,
        },
        {
          en: `Knowledge Test / English Proficiency Test Result Viewing System for Regiment and Battalion Commanders`,
          th: `ระบบดูผลการทดสอบความรู้ / การทดสอบภาษาอังกฤษ - ผบ.หน่วยระดับกรม และ ระดับกองพัน`,
        },
        {
          en: `Personnel Housing Allocation System for the Directorate of Personnel, Royal Thai Army`,
          th: `โปรแกรมระบบงานจัดกำลังพลเข้าบ้านพัก กพ.ทบ.`,
        },
      ],
      image: `work-experience/profile-3.jpg`,
      // galleryPhotos: [
      //   {
      //     original: rtaPic1,
      //     alt: `rta-1`,
      //     fullname: `work-experience/rta/rta-1.jpg`,
      //   },
      //   {
      //     original: rtaPic2,
      //     alt: `rta-2`,
      //     fullname: `work-experience/rta/rta-2.jpg`,
      //   },
      //   {
      //     original: rtaPic3,
      //     alt: `rta-3`,
      //     fullname: `work-experience/rta/rta-3.jpg`,
      //   },
      //   {
      //     original: rtaPic4,
      //     alt: `rta-4`,
      //     fullname: `work-experience/rta/rta-4.jpg`,
      //   },
      //   {
      //     original: rtaPic5,
      //     alt: `rta-5`,
      //     fullname: `work-experience/rta/rta-5.jpg`,
      //   },
      //   {
      //     original: rtaPic6,
      //     alt: `rta-6`,
      //     fullname: `work-experience/rta/rta-6.jpg`,
      //   },
      // ],
    },
    {
      // 2
      startDate: {
        year: {
          en: `2018`,
          th: `${parseYearToBE(2018)}`,
        },
        month: {
          en: `Apr`,
          th: `เม.ย.`,
        },
      },
      endDate: {
        year: {
          en: `2019`,
          th: `${parseYearToBE(2019)}`,
        },
        month: {
          en: `Jun`,
          th: `มิ.ย.`,
        },
      },
      job: {
        en: `Software Engineer`,
        th: `วิศวกรซอฟต์แวร์`,
      },
      address: {
        en: `THiNKNET Co., Ltd., Bangkok`,
        th: `บริษัท ทิงค์เน็ต จำกัด (สำนักงานใหญ่) แขวงสีลม เขตบางรัก กทม.`,
      },
      description: [
        {
          en: `Maintained and solved any problems or bugs that may occur of all company's online products`,
          th: `บำรุงรักษาและแก้ไขปัญหาหรือข้อผิดพลาดที่อาจเกิดขึ้นกับผลิตภัณฑ์ที่กำลังออนไลน์อยู่ทั้งหมดของบริษัท`,
        },
        {
          en: `Summarized stats of banner viewing, job viewing and other additional of JobThai website followed the team leader`,
          th: `รวบรวมสถิติการเข้าดูแบนเนอร์ การเข้าดูงานและอื่นๆ ของเว็บไซต์ JobThai ตามที่ได้รับมอบหมาย`,
        },
        {
          en: `Developed some new product as assigned from production development department`,
          th: `พัฒนาผลิตภัณฑ์ใหม่ๆ ตามที่แผนก production development ออกแบบไว้`,
        },
        {
          en: `Contacted customer service department and reported the progress accuracy of a project`,
          th: `ติดต่อกับทางฝ่ายบริการลูกค้าเพื่อแก้ไขปัญหาและรายงานความคืบหน้าของโครงการ`,
        },
      ],
      projects: [
        {
          en: `JobThai.com`,
          th: `JobThai.com`,
        },
        {
          en: `THiNKNET website`,
          th: `THiNKNET website`,
        },
        {
          en: `Thinknet Corporate Page`,
          th: `Thinknet Corporate Page`,
        },
        {
          en: `Uniqlo Corporate Page`,
          th: `Uniqlo Corporate Page`,
        },
        {
          en: `Philip Morris Corporate Page`,
          th: `Philip Morris Corporate Page`,
        },
        {
          en: `CPF Corporate Page`,
          th: `CPF Corporate Page`,
        },
      ],
      acquiredSkills: [
        `React.js`,
        `Next.js`,
        `Responsive Web Design`,
        `MongoDB`,
        `MySQL`,
        `Node.js`,
        `GraphQL`,
        `Git`,
        `Laravel`,
        `PHP`,
        `CSS`,
        `HTML5`,
        `HTML`,
      ],
      image: `work-experience/profile-2.jpg`,
    },
    {
      // 1
      startDate: {
        year: {
          en: `2018`,
          th: `${parseYearToBE(2018)}`,
        },
        month: {
          en: `Jan`,
          th: `ม.ค.`,
        },
      },
      endDate: {
        year: {
          en: `2018`,
          th: `${parseYearToBE(2018)}`,
        },
        month: {
          en: `Mar`,
          th: `มี.ค.`,
        },
      },
      job: {
        en: `Programmer`,
        th: `โปรแกรมเมอร์`,
      },
      address: {
        en: `STORESITE DEVELOPER CO.,LT, Bangkok`,
        th: `บริษัท สโตร์ไซต์ ดีเวลลอปเปอร์ จำกัด แขวงถนนนครไชยศรี เขตดุสิต กรุงเทพมหานคร`,
      },
      description: [
        {
          en: `Developed a frontend, backend and other systems as assigned by using the Laravel framework`,
          th: `พัฒนาเว็บแอปพลิเคชันในส่วนของระบบหน้าบ้าน หลังบ้านและอื่นๆ ตามที่ได้รับมอบหมายโดยใช้ Laravel framework ในการพัฒนาเป็นหลัก`,
        },
        {
          en: `Analyzed and designed a frontend and backend systems including relational database`,
          th: `วิเคราะห์และออกแบบในส่วนของระบบหน้าบ้านและหลังบ้าน รวมถึงการออกแบบฐานข้อมูลแบบ relational`,
        },
        {
          en: `Contacted customers and reported the progress accuracy of a project`,
          th: `ติดต่อลูกค้าเพื่อติดตามและรายงานผลความคืบหน้าของโครงการที่รับผิดชอบ`,
        },
      ],
      projects: [
        {
          en: `Web Application for Purchase Order Receipt System`,
          th: `เว็บแอปพลิเคชันรับสินค้าจากการสั่งซื้อ`,
        },
        {
          en: `Web Application for Book Reservation System`,
          th: `เว็บแอปพลิเคชันจองหนังสือ`,
        },
      ],
      acquiredSkills: [
        `MySQL`,
        `Vue.js`,
        `jQuery`,
        `JavaScript`,
        `Laravel`,
        `PHP`,
        `CSS`,
        `HTML5`,
        `HTML`,
      ],
      image: `work-experience/profile-1.jpg`,
    },
  ],
}

export default workExp
