import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import styled from "styled-components"

const Container = styled.div`
  text-align: ${({ align }) => align};
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
  margin-bottom: 1.25rem;
  width: 100%;
`

const Label = styled.span`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 28px;
  color: ${({ theme }) =>
    theme === `light` ? `rgb(0, 0, 0)` : `rgb(255, 255, 255)`};
  border-bottom: 1px solid
    ${({ theme }) =>
      theme === `light` ? `rgba(0, 0, 0, 0.1)` : `rgba(255, 255, 255, 0.2)`};
  transition: 0.3s;
`

const MainTitle = ({ title, align }) => {
  const { theme } = useSelector(({ mainReducer }) => mainReducer)

  return (
    <Container align={align}>
      <Label theme={theme}>{title}</Label>
    </Container>
  )
}

MainTitle.propTypes = {
  title: PropTypes.string,
  align: PropTypes.string,
}

MainTitle.defaultProps = {
  title: ``,
  align: `center`,
}

export default MainTitle
