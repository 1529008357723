import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import styled from "styled-components"

const Edu = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1.25rem;

  div.edu-left {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 250px;
    margin-right: 0.75rem;

    span {
      font-size: 1.5rem;
    }

    .edu-date {
      display: flex;
      flex-direction: column;
    }

    .dash {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    @media (max-width: 767px) {
      max-width: 140px;

      span {
        font-size: 1.25rem;
      }
    }
  }

  div.edu-right {
    width: 100%;

    p {
      margin: 0;

      &.place {
        font-size: 1.5rem;
      }
    }

    @media (max-width: 767px) {
      p {
        &.place {
          font-size: 1.25rem;
        }
      }
    }
  }
`

const Education = ({ data }) => {
  const eduData = data
  const { lang } = useSelector(({ mainReducer }) => mainReducer)

  return (
    <>
      {eduData.map((education, educationIndex) => {
        return (
          <Edu key={`edu_${educationIndex}`}>
            <div className="edu-left">
              <span className="edu-date">
                <span>{education.startDate.year[lang]}</span>
                <span>{education.startDate.month[lang]}</span>
              </span>
              <span className="dash">-</span>
              <span className="edu-date">
                <span>{education.endDate.year[lang]}</span>
                <span>{education.endDate.month[lang]}</span>
              </span>
            </div>
            <div className="edu-right">
              <p className="place">{education.university[lang]}</p>
              <p>
                {education.degree[lang]} ({education.major[lang]})
              </p>
            </div>
          </Edu>
        )
      })}
    </>
  )
}

Education.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.shape({
        year: PropTypes.shape({
          en: PropTypes.string,
          th: PropTypes.string,
        }),
        month: PropTypes.shape({
          en: PropTypes.string,
          th: PropTypes.string,
        }),
      }),
      endDate: PropTypes.shape({
        year: PropTypes.shape({
          en: PropTypes.string,
          th: PropTypes.string,
        }),
        month: PropTypes.shape({
          en: PropTypes.string,
          th: PropTypes.string,
        }),
      }),
      university: PropTypes.shape({
        en: PropTypes.string.isRequired,
        th: PropTypes.string.isRequired,
      }),
      degree: PropTypes.shape({
        en: PropTypes.string.isRequired,
        th: PropTypes.string.isRequired,
      }),
      major: PropTypes.shape({
        en: PropTypes.string.isRequired,
        th: PropTypes.string.isRequired,
      }),
    })
  ),
}

export default Education
