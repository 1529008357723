const developmentTools = {
  title: {
    en: `Development Tools`,
    th: `เครื่องมือในการพัฒนา`,
  },
  data: [
    {
      title: {
        en: `Libraries`,
        th: `Libraries`,
      },
      desc: [
        `Gatsby`,
        `Next.js`,
        `prop-types`,
        `styled-components`,
        `@reduxjs/toolkit`,
        `react-redux`,
        `redux-persist`,
        `axios`,
        `prettier`,
      ],
    },
    {
      title: {
        en: `UI Frameworks`,
        th: `UI Frameworks`,
      },
      desc: [`Material UI`, `Ant Design`, `Tailwind CSS`],
    },
    {
      title: {
        en: `Version Control`,
        th: `Version Control`,
      },
      desc: [`Bitbucket`, `GitHub`, `GitLab`],
    },
  ],
}

export default developmentTools
